import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import { Box, VStack, useColorModeValue, IconButton, SimpleGrid, Heading } from '@chakra-ui/react';
import { ArrowUpIcon } from '@chakra-ui/icons';
import FeaturedArticleCard from '../Blog/FeaturedArticleCard';
import useBlogPosts from '../Blog/hooks/useBlogPosts'; // Adjust the import path as needed

const MotionBox = motion(Box);

const ArticleLayout = ({ children, title, date, imageUrl, isCreate,...props }) => {
  const { posts } = useBlogPosts();

  const boxShadow = useColorModeValue('0px 4px 20px rgba(0, 0, 0, 0.05)', '0px 4px 20px rgba(0, 0, 0, 0.2)');
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.700', 'gray.200');
  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const featuredArticles = posts.filter((post, index) => index < 4); // just an example to pick first 4

  const renderFeaturedArticles = () => {
    return featuredArticles.map(article => (
      <FeaturedArticleCard key={article.id} article={article} />
    ));
  };
  useEffect(() => {
    const checkScroll = () => {
      if (!showScrollToTop && window.pageYOffset > 400) {
        setShowScrollToTop(true);
      } else if (showScrollToTop && window.pageYOffset <= 400) {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener('scroll', checkScroll);
    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  }, [showScrollToTop]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };


  return (
    <MotionBox
      initial="initial"
      animate="animate"
      variants={fadeIn}
      maxWidth="1000px"
      width="100%"
      mx="auto"
      my={12}
      p={8}
      borderRadius="lg"
      boxShadow={boxShadow}
      bg={bgColor}
      color={textColor}
      {...props}
    >
      <VStack spacing={8} align="start">
        {imageUrl && (
          <Box
            as="img"
            src={imageUrl}
            alt={title}
            borderRadius="lg"
            width="full"
            objectFit="cover"
            maxHeight="450px"
          />
        )}
        <Box as="article">
          {children}
        </Box>
      </VStack>
      {!isCreate && (
      <Box w="100%" mt={12}>
        <Heading size="lg" mb={6}>Featured Articles</Heading>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={5}>
          {renderFeaturedArticles()}
        </SimpleGrid>
      </Box>)}
      {showScrollToTop && (
        <IconButton
          icon={<ArrowUpIcon />}
          position="fixed"
          bottom="2rem"
          right="2rem"
          zIndex="tooltip"
          onClick={scrollToTop}
          aria-label="Scroll to top"
        />
      )}
    </MotionBox>
  );
};

export default ArticleLayout;
