import React from 'react';
import { FaTwitter, FaInstagram, FaTelegram } from 'react-icons/fa';
import { HStack, Icon, Link } from '@chakra-ui/react';

const SocialLinks = () => {

  const iconStyle = {
    transition: 'transform 0.2s', // Smooth transition for the transform property
    _hover: {
      transform: 'scale(1.2)', // Scale up the icon to 120% on hover
    },
  };
  return (
    <HStack justify="center" spacing={4}>
      <Link href="https://t.me/+DxGGjqT_ov80NWRk" isExternal>
        <Icon as={FaTelegram} w={10} h={10} color="gray.600" sx={iconStyle}/>
      </Link>
      <Link href="https://twitter.com/Call2actionnow" isExternal>
        <Icon as={FaTwitter} w={10} h={10} color="gray.600" sx={iconStyle}/>
      </Link>
      <Link href="https://www.instagram.com/call2actionnow/" isExternal>
        <Icon as={FaInstagram} w={10} h={10} color="gray.600" sx={iconStyle}/>
      </Link>

    </HStack>
  );
};

export default SocialLinks;
