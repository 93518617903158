import React from "react";
import { Image } from "@chakra-ui/react";

const Call2Action = () => (
  <Image
    src="/C2ALogo.png"
    alt="Call2Action logo"
    width={100}
    borderRadius="50%"
  />
);

export { Call2Action };
