import EventsImage from "../../../assets/EventsImage.jpg";
import ContactImage from "../../../assets/ContactImage.jpg";
import BlogImage from "../../../assets/BlogImage.jpg";
import AboutImage from "../../../assets/AboutImage.jpg";
import ResourcesImage from "../../../assets/ResourcesImage.jpg";

export const imagesData = [
  {
    image: AboutImage,
    header: "Dive Into Our Story",
    subtext: "Explore the journey that defines us",
    buttonText: "Learn More",
    buttonLink: "/about-us",
  },
  {
    image: EventsImage,
    header: "Unforgettable Experiences Await",
    subtext: "Join us and be part of our vibrant events",
    buttonText: "View Events",
    buttonLink: "/events",
  },
  {
    image: ContactImage,
    header: "Get in Touch with Us",
    subtext: "Questions? Ideas? Let's start a conversation!",
    buttonText: "Contact Now",
    buttonLink: "/contact-us",
  },
  {
    image: BlogImage,
    header: "Insights and Inspirations",
    subtext: "Read our latest thoughts and musings",
    buttonText: "Visit Blog",
    buttonLink: "/blog",
  },
  {
    image: ResourcesImage,
    header: "Empower Your Knowledge",
    subtext: "Browse our curated resources for your growth",
    buttonText: "Explore Resources",
    buttonLink: "/resources",
  },
];

