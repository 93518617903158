import React from 'react';
import { Heading, VStack, Image, Container } from '@chakra-ui/react';
import ConstructionImage from '../../assets/underconstruction.png'; // Replace with path to your construction image

const UnderConstruction = () => {
  return (
    <Container centerContent py={20}>
      <VStack spacing={6}>
        <Image
          src={ConstructionImage}
          alt="Under Construction"
          boxSize="550px"
          objectFit="cover"
        />
        <Heading> We're working hard to finish the development of this section. Stay tuned for updates. Thank you for your patience!</Heading>
      </VStack>
    </Container>
  );
};

export default UnderConstruction;
