import React, { useState, useEffect,  } from 'react';
import { Button, FormControl, FormLabel, Input, Text,Checkbox, Flex, Textarea, Heading, useTheme, Container, VStack} from '@chakra-ui/react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useMailchimp} from './hooks/useMailchimp';
import SimplifiedLayout from '../Layout/SimplifiedLayout';
import Confetti from 'react-confetti';
import SocialLinks from '../../utils/SocialLinks'

const ContactUs = () => {

  const initialFormData = {
    email: '',
    firstName: '',
    lastName: '',
    subject: '',
    message: '',
    subscribe: false,
  };
  const [showConfetti, setShowConfetti] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const { sendToEmail, isLoading, error } = useMailchimp();
  const [subscribe, setSubscribe] = useState(false);

  useEffect(() => {
    if (!isLoading && error) {
      toast.error(error.message || 'Failed to submit the form.');
    }
  }, [isLoading, error]);
  useEffect(() => {
    if (showConfetti) {
      const timer = setTimeout(() => setShowConfetti(false), 5000); // 5 seconds
      return () => clearTimeout(timer);
    }
  }, [showConfetti]);


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const completeFormData = {
      ...formData,
      subscribe,
    };

    if (completeFormData.email && completeFormData.firstName && completeFormData.email.includes('@')) {
      try {
        await sendToEmail(completeFormData);
        toast.success('Form submitted successfully!');
        setFormData(initialFormData);
        setSubscribe(false);
        setShowConfetti(true);
      } catch (err) {
        toast.error(`Submission failed: ${err.message}`);
      }
    } else {
      toast.error('Please fill in all required fields with valid information.');
    }
  };

  return (
    <SimplifiedLayout>
      <Container
        maxW={{ base: "90%", md: "80%", lg: "container.lg" }}
        minW={{ base: "300px", md: "500px", lg: "700px" }}
        centerContent
      >

      <VStack
          spacing={5}
          align="center"
          w="full"
          >
       {showConfetti && <Confetti />}
      <Heading as="h1" size="4xl" paddingTop="4rem" >
      Organize With Us
              </Heading><br/>
<SocialLinks/>
      <FormHeader>

      </FormHeader>
      <FormControl netlify>
        <FormLabel fontSize="lg">First Name</FormLabel>
        <StyledInput type="text" name="firstName" value={formData.firstName} onChange={handleChange} />
      </FormControl>
      <FormControl>
        <FormLabel fontSize="lg">Last Name</FormLabel>
        <StyledInput type="text" name="lastName" value={formData.lastName} onChange={handleChange} />
      </FormControl>
      <FormControl>
        <FormLabel fontSize="lg">Email</FormLabel>
        <StyledInput type="email" name="email" value={formData.email} onChange={handleChange} />
      </FormControl>
      <FormControl>
        <FormLabel fontSize="lg">Subject</FormLabel>
        <StyledInput type="text" name="subject" value={formData.subject} onChange={handleChange} />
      </FormControl>
      <FormControl>
        <FormLabel fontSize="lg">Message</FormLabel>
        <StyledTextArea type="text" name="message" value={formData.message} onChange={handleChange} minHeight="150px"/>
      </FormControl>
      <FormControl>
      <Flex width="100%" textAlign="left" paddingTop="2rem" direction="column" justifyContent="flex-start">
  <Checkbox
    isChecked={subscribe}
    onChange={() => setSubscribe(!subscribe)}
    colorScheme="green"
    iconColor="#420D09"
    iconSize="1.5em"
    borderColor="#420D09"
  >
    Subscribe to our newsletter
  </Checkbox>
  <Button
    width={{base: "50%", md:"10%"}}
    size="md"
    bg="#420D09"
    color="white"mt="4"
    align="left"
    onClick={(e) => handleSubmit(e)}
    _hover={{ bg: '#A45A52' }}
    >
        {isLoading ? (
          <div className="mc__alert mc__alert--sending">Sending...</div>
        ) : (
          <div className="mc__alert mc__alert--sending">Send</div>
        )}
      </Button>
  </Flex>

</FormControl>
</VStack>
</Container>
    </SimplifiedLayout>
  );
};
const FormHeader = (props) => {
  const theme = useTheme();

  // Define custom styles using theme values
  const customStyles = {
    textAlign: 'center',
    fontSize: theme.fontSizes['xl'],
    paddingBottom: theme.space[4],
    fontWeight: '600',
    padding: { base: theme.space[0], md: theme.space[10] },
  };

  return <Text {...customStyles} {...props} />;
};


export default ContactUs;



const StyledInput = styled(Input)`
  border: 2px solid #A45A52 !important;

  &:focus {
    border-color: #A45A52 !important;
    box-shadow: 0 0 0 1px #A45A52 !important;
  }
`;

const StyledTextArea = styled(Textarea)`
  border: 2px solid #A45A52 !important;

  &:focus {
    border-color: #A45A52 !important;
    box-shadow: 0 0 0 1px #A45A52 !important;
  }
`;
