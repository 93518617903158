import { useState } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const useImageUpload = ({uploadingImage}) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const uploadImage = async (file) => {
    setUploadError(null);

    if (!file) {
      setUploadError("File not provided for upload.");
      return;
    }

    const storage = getStorage();
    const storageRef = ref(storage, `images/${file.name}`);
    try {
      const snapshot = await uploadBytes(storageRef, file);

      const downloadURL = await getDownloadURL(snapshot.ref);
      setUploadProgress(100);
      setUploadedImageUrl(downloadURL);
      return downloadURL;
    } catch (error) {
      console.error('Error uploading image:', error);
      setUploadError(error);
      setUploadProgress(0);
      throw error;
    }
  };

  return { uploadImage, uploadProgress, uploadError, uploadedImageUrl };
};

export default useImageUpload;
