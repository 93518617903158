import React from 'react';
import { VStack, Container } from '@chakra-ui/react';

const SimplifiedLayout = ({ children }) => {
  return (
    <Container
    maxW="1000px"
    width="100%"
    mx="auto"
    my={12}
    px={{ base: 4, md: 8 }}
    py={8}>
      <VStack
        spacing={8}
        align="100%"
        w="full"
        bg="white"
        boxShadow="sm"
        p={8}
        borderRadius="lg"
      >
        {children}
      </VStack>
    </Container>
  );
};

export default SimplifiedLayout;
