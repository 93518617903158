import {extendTheme} from '@chakra-ui/react';

const theme = extendTheme({
  colors:
   ' #323E42',
  fonts: {
    heading: 'DM Sans',
    body: 'DM Sans',
  },
  breakpoints: {
    'sm': '30em',
    'md': '48em',
    'lg': '62em',
    'xl': '80em',
    '2xl': '96em',
  },
  styles: {
    global: {
      body: {
        overflowX: 'hidden',
        minHeight: '100vh',
        backgroundColor:"#F0EAD6",
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'scroll',
        backgroundSize: 'cover',
        color: 'blackGold.500',
      },
    }
  }
});

export default theme;
