/* eslint-disable no-unused-vars */
import React from "react";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "./components/Home/Home";
import Layout from "./components/Layout/Layout";
import Expertise from "./components/Expertise/Expertise";
import ContactUs from "./components/ContactUs/ContactUs";
import MyStory from "./components/MyStory/MyStory";
import Terms from "./components/Terms/Terms";
import TherapeuticApproachGuide from "./components/TherapeuticApproachGuide/TherapeuticApproachGuide";
import NotFound from "./components/NotFound/NotFound";
import { Flex } from "@chakra-ui/react";
import FAQ from "./components/FAQ/Faq";
import Blog from "./components/Blog/Blog";
import BlogPost from "./components/Blog/BlogPost";
import CreateBlogPost from "./components/Blog/CreateBlogPost";
import UnderConstruction from "./components/UnderConstruction/UnderContruction";
import {useLocation} from 'react-router-dom';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [showHeader, setShowHeader] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);



  return (
    <>
      <Layout showHeader={showHeader}>
        <Flex justifyContent="center">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/blog/:slug" element={<UnderConstruction/>} />
            <Route path="/create-blog-post" element={<UnderConstruction/>} />
            <Route exact path="/contact-us" element={<ContactUs />} />
            <Route exact path="/faq" element={<UnderConstruction />} />
            <Route exact path="/blog" element={<UnderConstruction />} />
            <Route exact path="/about-us" element={<UnderConstruction />} />
            <Route exact path="/events" element={<UnderConstruction />} />
            <Route exact path="/resources" element={<UnderConstruction />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Flex>
      </Layout>
    </>
  );
}

export default App;
