import React, {useState, useEffect} from 'react';
import {
  Box,
  Flex,
  Link,
  Text,
  Image,
  CloseButton,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';

import {Call2Action} from './Call2Action';
import styled from 'styled-components';
import watermelon from '../../assets/watermelon.png';

const Header = () => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);

  const headerLinks = [
    {name: 'Blog', href: '/blog'},
    {name: 'About Us', href: '/about-us'},
    {name: 'Resources', href: '/resources'},
    {name: 'Get In Touch', href: '/contact-us'},
    {name: 'FAQs', href: '/faq'},
  ];

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.scrollY;

      if (Math.abs(currentScrollPosition - lastScrollPosition) < 20) {
        return;
      }

      if (currentScrollPosition < lastScrollPosition) {
        setShowHeader(true);
      } else if (currentScrollPosition > lastScrollPosition) {
        setShowHeader(false);
      }
      setLastScrollPosition(currentScrollPosition);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollPosition]);

  return (
    <Box
      color="white"
      p={4}
      position="fixed"
      top="0"
      w="100%"
      zIndex="100"
      bg="transparent"
      transition="top 0.5s ease-in-out"
      display={showHeader ? 'block' : 'none'}
    >
      <Flex
        direction="row"
        align="center"
        justify="space-between"
        margin="auto"
      >
        <Flex align="center" flex="1">
          <Link href="/">
           <Call2Action />
          </Link>
        </Flex>

        <Flex align="center" flex="1" justify="flex-end">
  <Image
    src={watermelon}
    boxSize="10"
    onClick={onOpen}
    _hover={{ cursor: 'pointer' }}
  />
</Flex>

        {isOpen && (
          <SideBar onClose={onClose}>
          <Link href="/">
                        <Call2Action/>
</Link>
            {headerLinks.map((link, i) => (
              <Link
                key={i}
                mb={4}
                href={link.href}
                _hover={{textDecoration: 'none', transform: 'scale(1.05)'}}
                transition="transform 0.2s ease-in-out"
              >
                <LinkText fontSize={{base: '1.2rem', md: '1.2rem', lg: '3rem'}} fontWeight="900">
                  {link.name}
                </LinkText>
              </Link>
            ))}
          </SideBar>
        )}
      </Flex>

      {isOpen && <Backdrop onClick={onClose} />}
    </Box>
  );
};

const SideBar = ({onClose, ...props}) => {
  return (
    <Box
      position="fixed"
      right={0}
      top={0}
      bottom={0}
      w={{base: '55%', md: '40%', lg: '25%'}}
      bg="#420D09"
      color="#A45A52"
      p={10}
      pt={20}
      zIndex="120"
      overflowY="auto"
      transform="translateX(0)"
      transition="all 0.5s ease-in-out"
      {...props}
    >
      <StyledCloseButton onClick={onClose} />
      <VStack spacing={4} align="end">
        {props.children}
      </VStack>
    </Box>
  );
};

const StyledCloseButton = styled(CloseButton)`
  svg {
    width: 20px;
    height: 20px;
  }
`;


const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 110;
`;

const LinkText = styled(Text)`
  color: white;
`;

export default Header;
